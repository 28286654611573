import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import RingSize from "./Rowe Collection International Ring Size Chart.pdf"

const RingSizeChart = () => (
  <Layout>
    <SEO
      title="Ring Size Chart"
      keywords={[
        `australian`,
        `exclusive`,
        `jewellery`,
        `engagement`,
        `ring size`,
      ]}
    />
    <div className="ringsize">
      <div className="pdfstyles">
        <button type="submit" className="pdfbuttonmobile">
          <a
            href={RingSize}
            target="_blank"
            rel="noreferrer"
            className="downloadtextmobile"
          >
            Open PDF document in new window
          </a>
        </button>
      </div>
      <div className="pdfstyles">
        <button type="submit" className="pdfbuttonmobile">
          <a
            href={RingSize}
            target="_blank"
            rel="noreferrer"
            className="downloadtextmobile"
            download="Rowe Collection - International Ring Size Chart"
          >
            Download PDF
          </a>
        </button>
      </div>
      <div className="charttitle">INTERNATIONAL RING SIZE CHART</div>
      <table>
        <tr className="ringsizetitles">
          <th>
            Circumference
            <br />
            (mm)
          </th>
          <th>
            Diameter
            <br />
            (mm)
          </th>
          <th>
            UK
            <br />
            Australia{" "}
          </th>
          <th>
            USA
            <br />
            Canada{" "}
          </th>
          <th>
            Germany
            <br />
            Scandinavia{" "}
          </th>
          <th>
            Japan
            <br />
            China
            <br />
            India{" "}
          </th>
          <th>
            France
            <br />
            Russia{" "}
          </th>
        </tr>
        <tr>
          <td>44.14</td>
          <td>14.05</td>
          <td>F</td>
          <td>3</td>
          <td>14</td>
          <td>4</td>
          <td>44</td>
        </tr>
        <tr>
          <td>44.45</td>
          <td>14.15</td>
          <td>F ½</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
        </tr>
        <tr>
          <td>44.77</td>
          <td>14.25</td>
          <td>F ¾</td>
          <td>3 ¼</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
        </tr>
        <tr>
          <td>45.11</td>
          <td>14.36</td>
          <td>G</td>
          <td>-</td>
          <td>-</td>
          <td>5</td>
          <td>-</td>
        </tr>
        <tr>
          <td>45.40</td>
          <td>14.45</td>
          <td>G ¼</td>
          <td>3 ½</td>
          <td>14 ½</td>
          <td>-</td>
          <td>45 ½</td>
        </tr>
        <tr>
          <td>45.74</td>
          <td>14.56</td>
          <td>G ½</td>
          <td>-</td>
          <td>-</td>
          <td>6</td>
          <td>-</td>
        </tr>
        <tr>
          <td>46.02</td>
          <td>14.65</td>
          <td>H</td>
          <td>3 ¾</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
        </tr>
        <tr>
          <td>46.68</td>
          <td>14.86</td>
          <td>H ½</td>
          <td>4</td>
          <td>15</td>
          <td>7</td>
          <td>46 ½</td>
        </tr>
        <tr>
          <td>47.25</td>
          <td>15.04</td>
          <td>I</td>
          <td>4 ¼</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
        </tr>
        <tr>
          <td>47.97</td>
          <td>15.27</td>
          <td>I ½</td>
          <td>4 ½</td>
          <td>15 ¼</td>
          <td>8</td>
          <td>47 ¾</td>
        </tr>
        <tr>
          <td>48.38</td>
          <td>15.40</td>
          <td>J</td>
          <td>-</td>
          <td>15 ½</td>
          <td>-</td>
          <td>-</td>
        </tr>
        <tr>
          <td>48.79</td>
          <td>15.53</td>
          <td>J ¼</td>
          <td>4 ¾</td>
          <td>-</td>
          <td>-</td>
          <td>49</td>
        </tr>
        <tr>
          <td>49.32</td>
          <td>15.70</td>
          <td>J ½</td>
          <td>5</td>
          <td>15 ¾</td>
          <td>9</td>
          <td>-</td>
        </tr>
        <tr>
          <td>49.64</td>
          <td>15.80</td>
          <td>K</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
        </tr>
        <tr>
          <td>49.95</td>
          <td>15.90</td>
          <td>K ¼</td>
          <td>5 ¼</td>
          <td>-</td>
          <td>-</td>
          <td>50</td>
        </tr>
        <tr>
          <td>50.27</td>
          <td>16.00</td>
          <td>K ½</td>
          <td>-</td>
          <td>-</td>
          <td>10</td>
          <td>-</td>
        </tr>
        <tr>
          <td>50.58</td>
          <td>16.10</td>
          <td>L</td>
          <td>5 ½</td>
          <td>16</td>
          <td>-</td>
          <td>-</td>
        </tr>
        <tr>
          <td>51.21</td>
          <td>16.30</td>
          <td>L ¼</td>
          <td>5 ¾</td>
          <td>-</td>
          <td>-</td>
          <td>51 ¾</td>
        </tr>
        <tr>
          <td>51.55</td>
          <td>16.41</td>
          <td>L ½</td>
          <td>6</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
        </tr>
        <tr>
          <td>51.87</td>
          <td>16.51</td>
          <td>M</td>
          <td>-</td>
          <td>16 ½</td>
          <td>12</td>
          <td>-</td>
        </tr>
        <tr>
          <td>52.50</td>
          <td>16.71</td>
          <td>M ½</td>
          <td>6 ¼</td>
          <td>-</td>
          <td>-</td>
          <td>52 ¾</td>
        </tr>
        <tr>
          <td>53.16</td>
          <td>16.92</td>
          <td>N</td>
          <td>6 ½</td>
          <td>17</td>
          <td>13</td>
          <td>-</td>
        </tr>
        <tr>
          <td>53.82</td>
          <td>17.13</td>
          <td>N ½</td>
          <td>6 ¾</td>
          <td>-</td>
          <td>-</td>
          <td>54</td>
        </tr>
        <tr>
          <td>54.51</td>
          <td>17.35</td>
          <td>O</td>
          <td>7</td>
          <td>17 ¼</td>
          <td>14</td>
          <td>-</td>
        </tr>
        <tr>
          <td>54.82</td>
          <td>17.45</td>
          <td>O ½</td>
          <td>7 ¼</td>
          <td>-</td>
          <td>-</td>
          <td>55 ¼</td>
        </tr>
        <tr>
          <td>55.76</td>
          <td>17.75</td>
          <td>P</td>
          <td>7 ½</td>
          <td>17 ¾</td>
          <td>15</td>
          <td>-</td>
        </tr>
        <tr>
          <td>56.45</td>
          <td>17.97</td>
          <td>P ½</td>
          <td>7 ¾</td>
          <td>-</td>
          <td>-</td>
          <td>56 ½</td>
        </tr>
        <tr>
          <td>57.15</td>
          <td>18.19</td>
          <td>Q</td>
          <td>8</td>
          <td>18</td>
          <td>16</td>
          <td>-</td>
        </tr>
        <tr>
          <td>57.65</td>
          <td>18.35</td>
          <td>Q ½</td>
          <td>8 ¼</td>
          <td>-</td>
          <td>-</td>
          <td>57 ¾</td>
        </tr>
        <tr>
          <td>58.21</td>
          <td>18.53</td>
          <td>Q ¾</td>
          <td>8 ½</td>
          <td>18 ½</td>
          <td>17</td>
          <td>-</td>
        </tr>
        <tr>
          <td>58.47</td>
          <td>18.61</td>
          <td>R</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
        </tr>
        <tr>
          <td>58.72</td>
          <td>18.69</td>
          <td>R ¼</td>
          <td>8 ¾</td>
          <td>-</td>
          <td>-</td>
          <td>59</td>
        </tr>
        <tr>
          <td>59.06</td>
          <td>18.80</td>
          <td>R ½</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
        </tr>
        <tr>
          <td>59.34</td>
          <td>18.89</td>
          <td>R ¾</td>
          <td>9</td>
          <td>19</td>
          <td>18</td>
          <td>-</td>
        </tr>
        <tr>
          <td>60.00</td>
          <td>19.10</td>
          <td>S</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
        </tr>
        <tr>
          <td>60.38</td>
          <td>19.22</td>
          <td>S ¼</td>
          <td>9 ¼</td>
          <td>-</td>
          <td>-</td>
          <td>60 ¼</td>
        </tr>
        <tr>
          <td>60.66</td>
          <td>19.31</td>
          <td>S ½</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
        </tr>
        <tr>
          <td>60.98</td>
          <td>19.41</td>
          <td>S ¾</td>
          <td>9 ½</td>
          <td>19 ½</td>
          <td>19</td>
          <td>-</td>
        </tr>
        <tr>
          <td>61.29</td>
          <td>19.51</td>
          <td>T</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
        </tr>
        <tr>
          <td>61.64</td>
          <td>19.62</td>
          <td>T ¼</td>
          <td>9 ¾</td>
          <td>-</td>
          <td>-</td>
          <td>61 ½</td>
        </tr>
        <tr>
          <td>62.33</td>
          <td>19.84</td>
          <td>T ½</td>
          <td>10</td>
          <td>20</td>
          <td>20</td>
          <td>-</td>
        </tr>
        <tr>
          <td>62.89</td>
          <td>20.02</td>
          <td>U</td>
          <td>10 ¼</td>
          <td>-</td>
          <td>21</td>
          <td>62 ¾</td>
        </tr>
        <tr>
          <td>63.46</td>
          <td>20.20</td>
          <td>U ½</td>
          <td>10 ½</td>
          <td>20 ¼</td>
          <td>22</td>
          <td>-</td>
        </tr>
        <tr>
          <td>63.84</td>
          <td>20.32</td>
          <td>V</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
          <td>63</td>
        </tr>
        <tr>
          <td>64.21</td>
          <td>20.44</td>
          <td>V ¼</td>
          <td>10 ¾</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
        </tr>
        <tr>
          <td>64.97</td>
          <td>20.68</td>
          <td>V ½</td>
          <td>11</td>
          <td>20 ¾</td>
          <td>23</td>
          <td>-</td>
        </tr>
        <tr>
          <td>65.22</td>
          <td>20.76</td>
          <td>W</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
          <td>65</td>
        </tr>
        <tr>
          <td>65.50</td>
          <td>20.85</td>
          <td>W ¼</td>
          <td>11 ¼</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
        </tr>
        <tr>
          <td>65.78</td>
          <td>20.94</td>
          <td>W ½</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
        </tr>
        <tr>
          <td>66.22</td>
          <td>21.08</td>
          <td>W ¾</td>
          <td>11 ½</td>
          <td>21</td>
          <td>24</td>
          <td>-</td>
        </tr>
        <tr>
          <td>66.54</td>
          <td>21.18</td>
          <td>X</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
          <td>66 ¼</td>
        </tr>
        <tr>
          <td>66.73</td>
          <td>21.24</td>
          <td>X ¼</td>
          <td>11 ¾</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
        </tr>
        <tr>
          <td>66.92</td>
          <td>21.30</td>
          <td>X ½</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
        </tr>
        <tr>
          <td>67.51</td>
          <td>21.49</td>
          <td>Y</td>
          <td>12</td>
          <td>21 ¼</td>
          <td>25</td>
          <td>67 ½</td>
        </tr>
      </table>
      <div className="ringdisclaimer">
        <p>
          Disclaimer: this chart is a guide only. If you are unsure about your
          ring size, please contact us before <br />
          placing your order. We will hapilly assist you with choosing your
          correct ring size
        </p>
      </div>
    </div>
  </Layout>
)

export default RingSizeChart
